export default {
  'slot1': "7563395638",
  'slot2': "7877088960",
  'slot3': "6058742278",
  'firebaseConfig': {
  apiKey: "AIzaSyD2Fq25Bizl79723VyTZNweaa8YaN0v_rc",
  authDomain: "news13-live.firebaseapp.com",
  projectId: "news13-live",
  storageBucket: "news13-live.appspot.com",
  messagingSenderId: "560975903966",
  appId: "1:560975903966:web:0a45341f7ae9ab3ca6d64f",
  measurementId: "G-66MQTLGK01"
  }
}